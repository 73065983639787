
@import "variables.scss";
@import "mixins.scss";

//@import url('//fonts.googleapis.com/css?family=Quantico:400,400i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Quantico:700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,80&display=swap0');

// Global Components

body {
	@include sans-serif-font;
	font-size: 18px;
	color: $gray-dark;
}

// Typography

p {
	line-height: 1.5;
	margin: 30px 0;
	a {
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include sans-serif-font;
	font-weight: 700;
	text-transform: uppercase;
}

a {
	color: $gray-dark;
	&:hover,
	&:focus {
		color: $theme-primary;
	}
}

a img {
	&:hover,
	&:focus {
		cursor: zoom-in;
	}
}

blockquote {
  background: #f9f9f9;
  border-left: 5px solid #333333;
  margin: 30px 0;
  padding: 20px 20px;
}
blockquote p {
  	display: inline;
  	font-weight: 600;
	font-style: italic;
}

hr.small {
	max-width: 100px;
	margin: 15px auto;
	border-width: 4px;
	border-color: white;
}

del { opacity: 0.6; }

// Header
 
.intro-header {
	position: relative;
	background-color: $gray-light; 
	background: no-repeat center center; 
	background-attachment: scroll;
	overflow: hidden;

	.background {
		@include background-cover;
	}

	.background-overlay {
		position: absolute; width: 100%; height: 100%; top: 0; left: 0;
		// background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(51,51,51,0.8)), to(rgba(51,51,51,0.8)));
		// background-image: -webkit-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -moz-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -ms-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -o-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		background-image: linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.95));
	}

	.background-overlay-archive {
		position: absolute; width: 100%; height: 100%; top: 0; left: 0;
		background-color: rgba(26, 23, 26, 1);
	}

	.background-drop {
		@include background-cover;
		background-size: 50%;
		background-repeat: no-repeat;
		background-position: right 50px bottom;
	}

	/*&:hover .background {
		-moz-transform: scale(1.1,1.1);
		-webkit-transform: scale(1.1,1.1);
		transform: scale(1.1,1.1);
	}*/

	.site-heading,
	.post-heading {
		a {
			color: #ffffff;
			text-decoration: none;
		}

		a.site-link {
			text-decoration: none;
			color: #ffffff;
		}
	}

	.podcast-heading,
	.site-heading,
	.post-heading,
	.page-heading {
		padding: 100px 0 50px;
		color: white;
		@media only screen and (min-width: 768px) {
			padding: 150px 0;
		}
	}
	.site-heading-archive,
	.podcast-heading-archive {
		padding: 100px 0 50px;
		color: white;
	}
	.podcast-heading,
	.site-heading,
	.post-heading,
	.page-heading,
	.site-heading-archive,
	.podcast-heading-archive {
		hr { border-top: 10px solid #ffffff; border-radius: 25px; opacity: 0.25; }
		.time {
			@include sans-serif-font;
			text-transform: uppercase;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 2px;
			margin-top: 30px;
			display: none;
		}	
		.episode-info {
			margin: 0 0 18px;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			.right {
				float: right;
			}
		}
		h1 {
			margin-top: 0;
			font-weight: 700;
			text-transform: uppercase;
			font-size: 32px;
			line-height: 40px;
			letter-spacing: 3px;
		}
		.read-more {
			margin: 15px 0 0;
			@include sans-serif-font;
			font-size: 16px;
			font-weight: 700;

			a {
				cursor: pointer;
				@include sans-serif-font;
				color: $theme-secondary;
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 700;
				margin-right: 20px;

			}

			a:hover {
				color: #1a171a;
			}

			.listen-now {
				padding-bottom: 10px;

				a {
					font-size: 40px;
					//border-bottom: 2px solid rgba(255,255,255,0.05);
				}
			}

			@media only screen and (max-width: 768px) {
				.listen-now {
					a {
						font-size: 30px;
					}
				}
			}
		}
		.subtitle {
			font-size: 18px;
			line-height: 28px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 20px;
			a {
				color: $theme-secondary;
			}
			i {
				padding-right: 10px;
			}
			a:hover {
				text-decoration: none;
			}
		}
		.subheading {
			font-size: 18px;
			line-height: 28px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 15px 0 0;
		}
		.schedule {
			@include sans-serif-font;
			color: #ffffff;
			margin: 30px 0 0 0;
			padding-bottom: 0;
			font-size: 24px;
			line-height: 36px;
			// border-bottom: 2px solid rgba(255,255,255,0.05);
			display: inline;
			a {
				color: $theme-secondary;
			}
			i {
				padding-right: 10px;
			}
			a:hover {
				text-decoration: none;
			}
		}
		.archive-subheading {
			font-size: 24px;
			line-height: 28px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 0;
			background-color: rgba(51,51,51,0.8);
			padding: 0;

			@include sans-serif-font;
			color: #ffffff;
			padding-bottom: 0;
			font-size: 24px;
			line-height: 36px;
			border-bottom: 2px solid rgba(255,255,255,0.05);
			display: inline-block;
			color: $theme-secondary;

			a {
				color: $theme-secondary;
			}
			i {
				padding-right: 10px;
			}
			a:hover {
				text-decoration: none;
			}
		}
		.subscribe {
			color: #ffffff;
			margin-top: 30px;
			padding-bottom: 0;
			font-size: 26px;
			line-height: 36px;
			display: block;
			a {
				color: $theme-secondary;
				i {
					padding-right: 26px;
					margin-bottom: 20px;
				}
			}
			.seperator { 
				padding-right: 26px;
				opacity: 0.05;
			}
		}
		@media only screen and (max-width: 1240px) {
			hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			h1 {
				font-size: 24px;
				line-height: 28px;
			}
			.subtitle {
				font-size: 18px;
				line-height: 28px;
			}
			.time {
				font-size: 14px;
			}
			.subheading {
				font-size: 16px;
			}
		}
		@media only screen and (max-width: 959px) {
			hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			h1 {
				font-size: 20px;
				line-height: 28px;
			}
			.subtitle {
				font-size: 18px;
				line-height: 28px;
			}
			.schedule,
			.subscribe {
				font-size: 20px;
				line-height: 25px;
			}
			.subheading {
				display: none;
			}
			.runtime { display: none; }
		}
	}
	.podcast-heading {		
		img {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.site-heading {		
		img {
			width: 25%;
			margin-bottom: 20px;
			float: left;
			margin-right: 20px;
		}

		.episode-logo {			
			position: relative;

			img {
				width: 100%;
				border-radius: 5px;
				box-shadow: 2px 2px 2px #000;
			}
			.first {
				position: absolute;
				z-index: 3;
			}
			.second {
				position: absolute;
				left: 10px;
				right: 10px;
				top: 30px;	
				opacity: 0.6;	
				z-index: 2;
			}

			.third {
				position: absolute;
				left: 20px;
				right: 20px;
				top: 60px;	
				opacity: 0.3;	
				z-index: 1;
			}
			// img:nth-child(1) {
			// 	margin-top: 20px;
			// 	opacity: 0.3;
			// }
			// img:nth-child(2) {
			// 	position: absolute;
			// 	left: 10px;
			// 	right: 10px;
			// 	top: 10px;
			// 	opacity: 0.6;

			// }
			// img:nth-child(3) {
			// 	position: absolute;
			// 	left: 0;
			// 	top: 0;
			//}
		}
	}
	.site-subheading {
		border-top: 1px solid rgba(255,255,255,0.1);
		margin-top: 30px;
		padding-top: 30px;

		h1 {
			font-size: 24px;
			line-height: 28px;
		}

		.episode-logo {			
			img {
				margin-top: 0px;
				width: 100%;
				margin-bottom: 5px;
			}
		}

		.episode-info {
			font-size: 15px;
			font-weight: 700;
		}
		
		.subheading,
		.meta {
			font-size: 16px;
			line-height: 24px;
		}
		
		@media only screen and (max-width: 768px) {
			h1 {
				font-size: 20px;
				line-height: 28px;
			}
		}
		@media only screen and (max-width: 1240px) {
			h1 {
				font-size: 20px;
				line-height: 28px;
			}
		}
	}
	@media only screen and (max-width: 768px) {
		.podcast-heading {
			display: none;
		}
		.episode-logo {
			display: none;
		}
	}
	.post-heading {
		hr { border-top: 10px solid #ffffff; border-radius: 25px; opacity: 0.25; }
		.time {
			@include sans-serif-font;
		}
		.episode-info {
			margin: 0 0 18px;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			.right {
				float: right;
			}
		}
		h1 {
			@include sans-serif-font;
			font-size: 55px;
			line-height: 65px;
			letter-spacing: 3px;
			text-transform: uppercase;
		}
		.subheading,
		.meta {
			line-height: 1.1;
			display: block;
					
			font-weight:  600;
			text-transform:  uppercase;
		}
		.subheading {
			font-size: 30px;
			line-height: 40px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 15px 0 0;
		}
		.podcast {
			@include sans-serif-font;
			font-weight: 400;
			text-transform: uppercase;
			padding: 0 30px 0 0;
			color: $theme-secondary;
		}
		> .meta {    
			margin-top: 30px;
			> a {
				text-decoration: none;
				color: $theme-secondary;
				&:hover,
				&:focus {
					color: $theme-secondary;
				}
			}
		}
		@media only screen and (min-width: 768px) {
			> a {
				> .post-title {
					font-size: 36px;
				}
			}
		}
		@media only screen and (max-width: 1240px) {
			hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			h1 {
				font-size: 45px;
				line-height: 55px;
			}
			.subtitle {
				font-size: 24px;
				line-height: 30px;
			}
			.subheading,
			.meta {
				font-size: 18px;
				line-height: 26px;
			}
		}
		@media only screen and (max-width: 959px) {
			h1 {
				font-size: 35px;
				line-height: 45px;
			}
			.subtitle {
				font-size: 18px;
				line-height: 28px;
			}
			.subheading,
			.meta {
				font-size: 18px;
				line-height: 28px;
			}
		}
		@media only screen and (max-width: 768px) {
			h1 {
				font-size: 32px;
				line-height: 38px;
			}
			.subtitle {
				font-size: 18px;
				line-height: 28px;
			}
			.meta {				
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.hosts {
		margin: 0;
		padding: 0;
		text-align: center;
		margin: 40px 0 0;
		
		div {
			font-size: 16px;
			margin-bottom: 20px;
			color: #fff;
			line-height: 1.25em;
			text-transform: uppercase;
			position: relative;
			flex: 1 0 auto;
			position: relative;

			em {
				letter-spacing: 2.4px;
				font-size: 12px;
				font-style: normal;
			}

			img {
				border-radius: 100%;
				float: none;
				width: 100%;
				margin: 0 auto 20px;
				-moz-box-shadow: 0px 6px 5px #000;
				-webkit-box-shadow: 0px 6px 5px #000;
				box-shadow: 0px 6px 5px #000;
			}

			a {
				text-decoration: none;
				display: block;
				width: 100%;

				img:hover, img:focus {
					cursor: pointer;
				}	
			}	
		}
	}
	.who {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #FAD923;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #DF8413;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #FAD923;
				}

				a:hover {
					color: #DF8413;
				}
			}
		}
		.hosts {
			div {
				a {
					color: #FAD923;
				}	
				a:hover {
					color: #DF8413;
				}
			}
		}
	}
	.trek {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #3F67BC;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #CEAED6;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #3F67BC;
				}

				a:hover {
					color: #CEAED6;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: #3F67BC;
				}	
				a:hover {
					color: #CEAED6;
				}
			}
		}
	}
	.comics {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #A80F0F;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #0099FF;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #A80F0F;
				}

				a:hover {
					color: #0099FF;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: #A80F0F;
				}	
				a:hover {
					color: #0099FF;
				}
			}
		}
	}
	.tech {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #EAB458;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #A80F0F;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #EAB458;
				}

				a:hover {
					color: #A80F0F;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: #EAB458;
				}	
				a:hover {
					color: #A80F0F;
				}
			}
		}
	}
	.extreme {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #32BA66;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #7E28D5;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #32BA66;
				}

				a:hover {
					color: #7E28D5;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: #32BA66
				}	
				a:hover {
					color: #7E28D5;
				}
			}
		}
	}
	.fandom {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: rgb(109, 111, 109);
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #cccccc;
		}
		.site-heading {	
			.subheading {				
				a {
					color: rgb(109, 111, 109);
				}

				a:hover {
					color: #cccccc;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: rgb(109, 111, 109);
				}	
				a:hover {
					color: #cccccc;
				}
			}
		}
	}
	.terminus {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #295A93;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #E1B77F;
		}
		.site-heading {	
			.subheading {				
				a {
					color: #295A93;
				}

				a:hover {
					color: #E1B77F;
				}
			}
		}
		.hosts {
			div {	
				a {
					color: #295A93;
				}	
				a:hover {
					color: #E1B77F;
				}
			}
		}
	}
	.network {
		.episode-info {
			color: #FFFFFF;
			opacity: 0.3;
		}
		.subscribe a, .schedule a, .read-more a {
			color: #3399CC;
		}
		.subscribe a:hover, .schedule a:hover, .read-more a:hover {
			color: #A80F0F;
		}
		.site-heading {
	
			color: #ffffff;
			text-align: center;
			
			h2 {
				margin-top: 10px;
				line-height: 40px;
				letter-spacing: 3px;
				text-transform: none;
				font-size: 36px;
				font-weight: 600;
				font-style: normal;
				
				@media only screen and (max-width: 992px) {
					font-size: 30px;
				}
			}
	
			.subheading {
				font-size: 18px;
				line-height: 28px;
				display: block;
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-weight: 400;
				margin: 30px 0;
				
				a {
					color: #3399CC;
					text-decoration: none;
					font-size: 24px;
				}

				a:hover {
					color: #a80f0f;
				}
			}
		}
		.hosts {
			div {	
				img {
					max-width: 120px;
				}
				a {
					color: #3399CC;
				}	
				a:hover {
					color: #a80f0f;
				}
			}
		}
	}
	.hero {
		.site-heading {
	
			color: #ffffff;
			text-align: center;
			
			h2 {
				margin-top: 10px;
				line-height: 40px;
				letter-spacing: 3px;
				text-transform: none;
				font-size: 36px;
				font-weight: 600;
				font-style: normal;
				
				@media only screen and (max-width: 992px) {
					font-size: 30px;
				}
			}
	
			.subheading {
				font-size: 18px;
				line-height: 28px;
				display: block;
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-weight: 400;
				margin: 30px 0;
				
				a {
					text-decoration: none;
					font-size: 24px;
				}
			}
		}
		.hosts {
			div {	
				img {
					max-width: 120px;
				}
			}
		}
	}
	.youtube {
		.site-heading {	
			color: #ffffff;
			text-align: center;
			padding-top: 60px;
			margin: 0;
			padding-bottom: 0;
			
			h2 {
				margin-top: 10px;
				line-height: 40px;
				letter-spacing: 3px;
				font-size: 32px;

				font-weight: 700;
				text-shadow: 2px 2px #000;
				font-style: italic;
				
				@media only screen and (max-width: 992px) {
					font-size: 28px;
				}
			}
		}
		.youtube-heading {
			padding: 50px 0 100px 0;
			color: white;
		}		
	}
}

// Post Previews
.post-previews {
	padding-top: 50px;
	padding-bottom: 50px;
	//background-color: #1a171a;

	h3.section-title {
		@include sans-serif-font;
		font-size: 21px;
		color: #1a171a;
		font-weight: 600;
		text-transform: uppercase;
		margin: 30px 0 56px 0;
		letter-spacing: 2px;
	}
}

// Post Preview Pages

.post-preview {
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;
	background-color: $gray-light; 
	background: no-repeat center center; 
	background-attachment: scroll;

	.background {
		@include background-cover;
	}

	/*&:hover .background {
		-moz-transform: scale(1.1,1.1);
		-webkit-transform: scale(1.1,1.1);
		transform: scale(1.1,1.1);
	}*/
	
	.background-overlay {
		position: absolute; width: 100%; height: 100%; top: 0; left: 0;
		// background-image: -webkit-gradient(linear, left, bottom, from(rgba(51,51,51,0.4)), to(rgba(51,51,51,1) 90%));
		// background-image: -webkit-linear-gradient(top, rgba(51,51,51,0.4), rgba(51,51,51,1) 90%);
		// background-image: -moz-linear-gradient(top, rgba(51,51,51,0.4), rgba(51,51,51,1) 90%);
		// background-image: -ms-linear-gradient(top, rgba(51,51,51,0.4), rgba(51,51,51,1) 90%);
		// background-image: -o-linear-gradient(top, rgba(51,51,51,0.4), rgba(51,51,51,1) 90%);
		background-image: linear-gradient(rgba(51,51,51,0.4),rgba(51,51,51,1) 90%);
	}

	> a {
		display: block;
		padding: 30px;
		padding-top: 200px;
		padding-bottom: 10px;
		color: #ffffff;
		position: relative;
		&:hover,
		&:focus {
			text-decoration: none;
			/*> .read-more {
			color: #1a171a;
			}*/
		}
		> hr { border-top: 10px solid #ffffff; border-radius: 25px; opacity: 0.25; }
		> h1 {
				margin-top: 0;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 2px;
				font-size: 32px;
				line-height: 40px;
		}
		> .time {
			@include sans-serif-font;
			margin-top: 20px;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 2px;

			.author {
				color: $theme-secondary;
			}
		}
		> .episode-info {
			margin: 0 0 18px;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			font-size: 16px;
			line-height: 26px;
			.right {
				float: right;
			}
		}
		> .subtitle {
			font-size: 26px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 0;
		}
		> .meta {    
			font-style: italic;
			margin-top: 30px;
			.author {
				text-decoration: underline;
				&:hover,
				&:focus {
					color: $theme-secondary;
				}
			}
		}
		> .subheading {
			font-size: 16px;
			line-height: 26px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 0;

			p {
				font-size: 16px;
				line-height: 26px;
				margin: 0;
			}
		}
		> .read-more {
			margin: 10px 0 20px;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			text-decoration: none;
			font-size: 16px;
			line-height: 26px;
		}
		@media only screen and (max-width: 1240px) {
			> hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			> h1 {
				font-size: 24px;
				line-height: 28px;
			}
			> .subtitle {
				font-size: 24px;
				line-height: 30px;
			}
		}
		@media only screen and (max-width: 959px) {
			> hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			> h1 {
				font-size: 20px;
				line-height: 28px;
			}
			> .subtitle {
				font-size: 18px;
				line-height: 28px;
			}
			> .subheading {
				display: none;
			}
		}
	}
	> .post-meta {
		color: $gray-light;
		font-size: 18px;
		font-style: italic;
		margin-top: 0;
		> a {
			text-decoration: underline;
			color: $gray-dark;
			&:hover,
			&:focus {
				color: $theme-primary;
				/*text-decoration: underline;*/
			}
		}
	}
	@media only screen and (min-width: 768px) {
		> a {
			> .post-title {
				font-size: 36px;
			}
		}
	}
}

// Podcast Post Preview Pages

.podcast-post-previews {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #1a171a;

	h3.section-title {
		@include sans-serif-font;
		font-size: 21px;
		color: #ffffff;
		font-weight: 600;
		text-transform: uppercase;
		margin: 30px 0 56px 0;
		letter-spacing: 2px;
	}
}

.podcast-post-preview {
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;
	background-color: $gray-light; 
	background: no-repeat center center; 
	background-attachment: scroll;
	height: 350px;

	.background {
		@include background-cover;
		
		-moz-transition: all .5s;
		-webkit-transition: all .5s;
		transition: all .5s;
		-moz-transform: scale(1,1);
		-webkit-transform: scale(1,1);
		transform: scale(1,1);
	}

	&:hover .background {
		-moz-transform: scale(1.25,1.25);
		-webkit-transform: scale(1.25,1.25);
		transform: scale(1.25,1.25);
	}
	
	.background-overlay {
		position: absolute; width: 100%; height: 100%; top: 0; left: 0;
		// background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(51,51,51,0.8)), to(rgba(51,51,51,0.8)));
		// background-image: -webkit-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -moz-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -ms-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		// background-image: -o-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
		background-image: linear-gradient(rgba(51,51,51,0.8),rgba(51,51,51,0.8));
		cursor: pointer;
	}

	> a {
		display: block;
		padding: 30px 30px 0;
		color: #ffffff;
		position: relative;

		&:hover,
		&:focus {
			text-decoration: none;
			/*> .read-more {
			color: #1a171a;
			}*/
		}
		> hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25;}
		> h1 {
				margin-top: 0;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 2px;
				font-size: 32px;
				line-height: 40px;
		}
		> .time {
			@include sans-serif-font;
			margin-top: 0;
			font-size: 14px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 2px;
		}
		> .episode-info {
			margin: 0 0 18px;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 700;
			font-size: 16px;
			line-height: 26px;
			.right {
				float: right;
			}
		}
		> .subtitle {
			font-size: 26px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 0;
		}
		> .subheading {
			font-size: 18px;
			line-height: 28px;
			display: block;
			@include sans-serif-font;
			font-weight: 400;
			margin: 10px 0 0;
		}
		@media only screen and (max-width: 1240px) {
			> hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			> h1 {
				font-size: 24px;
				line-height: 28px;
			}
			> .subtitle {
				font-size: 24px;
				line-height: 30px;
			}
			> .subheading {
				display: none;
			}
		}
		@media only screen and (max-width: 959px) {
			> hr { border-top: 5px solid #ffffff; border-radius: 25px; opacity: 0.25; }
			> h1 {
				font-size: 20px;
				line-height: 28px;
			}
			> .subtitle {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}
	> .read-more {
		margin: 18px 0 0;
		@include sans-serif-font;
		color: $theme-secondary;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 700;
		text-decoration: none;
		font-size: 16px;
		line-height: 26px;
		position: relative;
		padding: 0 30px 0;
		float: left;

		a {
			cursor: pointer;
			margin: 18px 0 0;
			@include sans-serif-font;
			color: $theme-secondary;
			text-transform: uppercase;
			font-size: 16px;
			font-weight: 700;
			margin-right: 20px;
		}

		a:last-child {
			margin-right: 0;
		}


		a:hover {
			color: #1a171a;
			text-decoration: none;
		}
	}
	> .post-meta {
		color: $gray-light;
		font-size: 18px;
		font-style: italic;
		margin-top: 0;
		> a {
			text-decoration: underline;
			color: $gray-dark;
			&:hover,
			&:focus {
				color: $theme-primary;
				/*text-decoration: underline;*/
			}
		}
	}
	@media only screen and (min-width: 768px) {
		> a {
			> .post-title {
				font-size: 36px;
			}
		}
	}
}

// Podcast Post, Preview, Pages

article {
	margin-bottom: 30px;

	img {
		max-width: 100%;
		height: auto;
    	margin: 0 0 0 0;
	}

	a {
		color: #333;
    	text-decoration: underline;
		text-decoration: none;
		// border-bottom: 1px solid #999999;
		font-weight: 600;
	}

	a:hover, a:active, a:focus {
		text-decoration: none;
	}

	p {
		img {
			margin: 30px 0;
			display: block;
			width: 100%;
			height: auto;
		}
	}


	.galleria {
		width: 100%;

		a {
			display: block;

			img {
				width: 100%;
				height: auto;
				margin-bottom: 30px;
				border-radius: 10px;
				cursor: pointer;
			}
		}
	}

	// Post Author
	.author-section {
		padding-bottom: 20px;

		.avatar {
			margin-top: 20px;
			border-top-left-radius: 50% 50%;
			border-top-right-radius: 50% 50%;
			border-bottom-right-radius: 50% 50%;
			border-bottom-left-radius: 50% 50%;
		}
		.name {
			text-transform: uppercase;
		}
		.description {
			color: $gray;
			font-size: 16px;
			line-height: 26px;
			font-style: italic;
		}
	}
}

// Sections

.section-heading {
	font-size: 36px;
	margin-top: 60px;
	font-weight: 700;
}

.caption {
	text-align: center;
	font-size: 14px;
	padding: 10px;
	font-style: italic;
	margin: 0;
	display: block;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

footer {
	padding: 50px 0 65px;
	padding: 50px 0 0;
	// margin-top: 20px;

	background-color: #0d0d0d;
	//border-top: 1px solid #eee;

	.podcast-heading {

		color: #ffffff;
		
		a {
			color: #3399CC;
		}
		
		img {
			width: 100%;
			margin-bottom: 5px;
		}

		.subtitle {
			font-size: 18px;
			line-height: 28px;
			display: block;
			font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-weight: 400;
			margin: 10px 0 20px;
		}
		.schedule {
			font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
			color: #ffffff;
			padding: 15px 0 0;
			font-size: 24px;
			line-height: 36px;
			display: inline;
			
			i {
				padding-right: 10px;
			}
		}
		.subscribe {
			margin-top: 30px;
			padding-bottom: 0;
			font-size: 26px;
			line-height: 36px;
			display: block;
			
			a i {
				padding-right: 26px;
			}
		}
	}
	.site-heading {

		color: #ffffff;
		text-align: center;
		padding-top: 40px;
		
		h2 {
			margin-top: 10px;
			line-height: 40px;
			letter-spacing: 3px;
			text-transform: none;
			font-size: 36px;
			font-weight: 600;
			font-style: normal;
			
			@media only screen and (max-width: 992px) {
				font-size: 30px;
			}
		}

		.subheading {
			font-size: 18px;
			line-height: 28px;
			display: block;
			font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
			font-weight: 400;
			margin: 30px 0;
			
			a {
				color: #3399CC;
				text-decoration: none;
				font-size: 24px;
			}
		}
	}

	.list-inline {
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			padding-right: 5px;
			padding-left: 5px;
		}
	}
	hr {
		border-top: 5px solid #1c1c1c;
		margin-top: 40px;
    	padding-top: 40px;
		margin-bottom: 0;
		clear: both;
	}
	.about {
		@include sans-serif-font;
		text-align: center;
		color: #ffffff;
		margin-bottom: 0;

		h2 {
			text-transform: none;
			font-size: 48px;
			font-weight: 400;
		}

		a {
			color: #3399CC;
			text-decoration: none;
			font-size: 24px;
		}
	}
	.about-grits {
		@include sans-serif-font;
		text-align: center;
		margin-bottom: 0;
		margin-top: 0;
		color: #999;
		font-size: 16px;

		a {
			color: $theme-secondary;
			text-decoration: none;
		}
	}
	.copyright {
		@include sans-serif-font;
		text-transform: uppercase;
		font-size: 11px;
		text-align: center;
		margin-bottom: 20px;
	
		color: #aaaaaa;	
	}
	ul.hosts {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		margin: 40px 0;
		
		display: -webkit-flex; /* Safari */
		-webkit-flex-wrap: wrap; /* Safari 6.1+ */
		display: flex;   
		flex-wrap: wrap;

		li {
			font-size: 16px;
			margin-bottom: 20px;
			color: #fff;
			line-height: 1.25em;
			text-transform: uppercase;
			position: relative;
			flex: 1 0 auto;

			em {
				letter-spacing: 2.4px;
				font-size: 12px;
				font-style: normal;
			}

			img {
				border-radius: 100%;
				-moz-box-shadow: 0px 6px 5px #000;
				-webkit-box-shadow: 0px 6px 5px #000;
				box-shadow: 0px 6px 5px #000;
				max-width: 120px;
				margin-bottom: 20px;
			}

			a {
				color: $theme-secondary;
				text-decoration: none;

				img:hover, img:focus {
					cursor: pointer;
				}	
			}
		}
	}
}

// Contact Form Styles

.floating-label-form-group {
	font-size: 14px;
	position: relative;
	margin-bottom: 0;
	padding-bottom: 0.5em;
	border-bottom: 1px solid $gray-lighter;
	input,
	textarea {
		z-index: 1;
		position: relative;
		padding-right: 0;
		padding-left: 0;
		border: none;
		border-radius: 0;
		font-size: 1.5em;
		background: none;
		box-shadow: none !important;
		resize: none;
	}
	label {
		display: block;
		z-index: 0;
		position: relative;
		top: 2em;
		margin: 0;
		font-size: 0.85em;
		line-height: 1.764705882em;
		vertical-align: middle;
		vertical-align: baseline;
		opacity: 0;
		-webkit-transition: top 0.3s ease,opacity 0.3s ease;
		-moz-transition: top 0.3s ease,opacity 0.3s ease;
		-ms-transition: top 0.3s ease,opacity 0.3s ease;
		transition: top 0.3s ease,opacity 0.3s ease;
	}
	&::not(:first-child) {
		padding-left: 14px;
		border-left: 1px solid $gray-lighter;
	}
}

.floating-label-form-group-with-value {
	label {
		top: 0;
		opacity: 1;
	}
}

.floating-label-form-group-with-focus {
	label {
		color: $theme-primary;
	}
}

form .row:first-child .floating-label-form-group {
	border-top: 1px solid $gray-lighter;
}

// Button Styles

.btn {
	@include sans-serif-font;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 800;
	letter-spacing: 1px;
	border-radius: 0;
	padding: 15px 25px;
}

.btn-lg {
	font-size: 16px;
	padding: 25px 35px;
}

.btn-default {
	&:hover,
	&:focus {
		background-color: $theme-primary;
		border: 1px solid $theme-primary;
		color: white;
	}
}

// Pager Styling

.pager {

	margin: 20px 0 0;

	li {
		> a,
		> span {
			@include sans-serif-font;
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 800;
			letter-spacing: 1px;
			padding: 15px 25px;
			background-color: white;
			border-radius: 0;
			margin-top: 10px;
		}

		> a:hover,
		> a:focus {
			color: white;
			background-color: $theme-primary;
			border: 1px solid $theme-primary;
		}

		@media only screen and (max-width: 576px) {
			> a,
			> span {
				width: 100%;
			}
			> span:last-child(1) {
				margin-bottom: 15px;
			}
		}
	}

	.disabled {
		> a,
		> a:hover,
		> a:focus,
		> span {
			color: $gray-light;
			background-color: $gray-dark;
			cursor: not-allowed;
		}
	}
}

// Highlight Color Customization

::-moz-selection {
	color: white;
	text-shadow: none;
	background: $theme-primary;
}

::selection {
	color: white;
	text-shadow: none;
	background: $theme-primary;
}

img::selection {
	color: white;
	background: transparent;
}

img::-moz-selection {
	color: white;
	background: transparent;
}

body {
	-webkit-tap-highlight-color: $theme-primary;
}















/* BBPlayer */
.playControls {
    position: fixed;
	bottom: 0;
    width: 100%;
    -webkit-perspective: 900;
    perspective: 900;
    -webkit-perspective-origin: 80% 100%;
    perspective-origin: 80% 100%;
    z-index: 1000;
	color: rgba(51,51,51,1.0);
	//border-top: 1px solid #f2f2f2;
	@include sans-serif-font;
	height: 64px;
	
	background-image: linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,1));
}
.bbplayercenter { 
	position: relative; 
	left: 50%; 
	float: left;

	color: #ffffff;
	width: 100%;
	float: none;
	left: 0;
	@include sans-serif-font;	

	@media only screen and (max-width: 576px) {
		background-color: #1a171a;
		width: 100%;
		float: none;
		left: 0;
		margin-top: 4px;
	}
}
// .bbplayer {
//     //color: rgba(26, 23, 26,1.0);
//     color: rgba(255, 255, 255,1);

// 	margin: 0 auto;
// 	display: block;
// 	position: relative; left: -50%; float: left; 
// 	width: 100%;
// 	height: 100%;
// 	float: none;
// 	left: 0;
// 	@include sans-serif-font;	

// 	.bb-rewind {
// 		background-size: cover;
// 		height: 24px;
// 		width: 60px;
// 		float: left;
// 		display: none;
// 	}
// 	.bb-play,
// 	.bb-playing {
// 		background-size: cover;
// 		background-position: center; 
// 		height: 24px;
// 		width: 40px;
// 		border: none;
// 		float: left;

// 		float: none;
// 		position: absolute;
// 		top: 32px;
// 		left: 56px; 
// 	}
// 	.bb-cancel {
// 		background-size: cover;
// 		background-position: center; 
// 		height: 24px;
// 		width: 60px;
// 		border: none;

// 		float: none;
// 		position: absolute;
// 		top: 16px;
// 		right: 12px;
// 		cursor: pointer;
// 	}
// 	.bb-forward {
// 		background-size: cover;
// 		height: 24x;
// 		width: 60px;
// 		margin-right: auto 5px;
// 		float: left;
// 		display: none;
// 	}
// 	.bb-trackTime {
// 		line-height: 24px;
// 		margin: auto 60px;
// 		float: left;
		
// 		float: none;
// 		position: absolute;
// 		top: 34px;
// 		left: 40px;
// 		font-weight: 600;
// 		font-size: 14px;
// 		line-height: 20px;
// 	}
// 	.bb-progress {
// 		width: 200px;
// 		height: 24px;
// 		margin: 0 10px 0;
// 		float: left;
// 		position: relative;
		
// 		float: none;
// 		position: absolute;
// 		top: 18px;
// 		left: 140px;
// 		@include sans-serif-font;		

// 		.progress {
// 			width: 200px;
// 			height: 2px;
// 			margin: 26px 0 0;
// 			float: left;
// 			box-shadow: inset 0 1px 2px rgba(26, 23, 26,0.2);
// 			cursor: pointer;
// 			.progress-bar {
// 				background-color: rgba(26, 23, 26,1.0);
// 				background-color: $theme-secondary;
// 				cursor: pointer;
// 			}
// 			.progress-dot {
// 				-moz-border-radius: 10px;
// 				-webkit-border-radius: 10px;
// 				border-radius: 10px; /* future proofing */
// 				width: 8px;
// 				height: 8px;
// 				position: absolute;
// 				background-color: rgba(26, 23, 26,1.0);
// 				background-color: #ffffff;
// 				top: 23px;
// 				left: -4px;
// 				z-index: 4000;
// 				cursor: pointer;
// 			}
// 		}
// 	}
// 	.bb-trackLength {
// 		margin: auto 5px;
// 		float: left;

// 		float: none;
// 		position: absolute;
// 		top: 34px;
// 		left: 365px;
// 		@include sans-serif-font;	
// 		font-weight: 600;
// 		font-size: 14px;
// 		line-height: 20px;
// 	}
// 	.bb-trackTitle {
// 		width: auto;
// 		margin: auto 5px;
// 		white-space: nowrap;
// 		float: left;		
// 		float: none; 
// 		position: absolute; 
// 		left: 70px; 
// 		margin: 0; 
// 		line-height: normal; 
// 		top: 0; 
// 		@include sans-serif-font;
// 		font-size: 16px; 
// 		font-weight: 600;
// 		line-height: 38px;
// 		color: #3399CC;
// 		text-transform: uppercase;
// 	}
// 	.bb-trackSubTitle { float: none; position: absolute; left: 104px; margin: 0; line-height: normal; top: 24px; font-size: 15px; color: #b8b7ad;
// 	    @include sans-serif-font;
// 		display: none;
// 	}
// 	.bb-coverContainer { 
// 		position: relative; width: 45px; height: 45px; margin: 12px; 
// 		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// 	}
// 	.bb-albumCover {
// 		margin: 5px 5px;
// 		background-size: cover;
// 		background-position: center; 
// 		float: left;
// 		border: none;

// 		position: absolute; 
// 		height: 100%;
// 		width: 100%;
// 		top: 0; left: 0; 
// 		margin: 0;
// 		-moz-border-radius: 2px;
// 		-webkit-border-radius: 2px;
// 		border-radius: 2px; 
// 	}
// 	.bb-albumCoverOverlay {
// 		height: 100%;
// 		width: 100%;
// 		position: absolute;
// 		-webkit-background-size: cover;
// 		-moz-background-size: cover;
// 		background-size: cover;
// 		-o-background-size: cover;
// 		background-position: center;
// 		position: absolute;  top: 0; left: 0;
// 		-moz-border-radius: 2px;
// 		-webkit-border-radius: 2px;
// 		border-radius: 2px; 
// 	}

// 	@media only screen and (max-width: 576px) {
// 		width: 100%;
// 		.playControls {
// 			background-image: linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,1));
// 		}
// 		.bbplayer {
// 			width: 100%;
// 			float: none;
// 			left: 0;
// 			height: 24px;
// 			background-color: none;
// 		}
// 		.bb-rewind { display: none; }
// 		.bb-forward { display: none; }
// 		.bb-play { 
// 			position: absolute; 
// 			right: 0; 
// 			left: initial; 
// 			top: 8px;
// 			background-color: red;
// 			background-color: rgba(26, 23, 26,1.0);
// 			z-index: 20; 
// 			height: 48px;
// 		} 
// 		.bb-cancel {
// 			position: absolute; 
// 			right: 5px; 
// 			left: initial; 
// 			top: 15px;
// 			background-color: blue;
// 			background-color: rgba(26, 23, 26,1.0);
// 			z-index: 20; 
// 			width: 30px;
// 			height: 24px;
// 			opacity: 0.2;
// 			display: none;
// 		}
// 		.bb-trackTime { display: none; }
// 		.bb-progress { 
// 			position: absolute;
// 			height: 0;
// 			margin: 0;
// 			float: none;
// 			width: 100%;    
// 			top: 0;
// 			left: 0; 
// 			z-index: 1000;
// 			.progress {
// 				width: 100%;
// 				height: 4px;
// 				margin: 0;
// 				float: none;
// 				background-color: rgba(255,255,255,0.4);
// 				border-radius: 0;

// 				.progress-bar {
// 					background-color: rgba(255,222,64,0.6);
// 				}
// 				.progress-dot { display: none; }
// 			}
// 		 }
// 		.bb-trackTitle { 
// 			margin: 0; 
// 			padding-right: 120px;
// 			padding-top: 12px;
// 			@include sans-serif-font;
// 			font-size: 14px; 
// 			line-height: 20px;
// 			text-transform: uppercase; 
// 			width: 100%;
// 			white-space: normal;
// 			text-align: left;
// 			vertical-align: middle;
// 		}
// 		.bb-trackLength { display: none; }
// 		.bb-trackSubTitle { float: none; position: absolute; left: 60px; margin: 0; line-height: normal; top: 32px; font-size: 12px; color: #b8b7ad;}
// 		.bb-coverContainer { position: relative; width: 50px; height: 50px; margin: 0; }
// 		.bb-albumCover { position: absolute; height: 100%; width: 100%; top: 0; left: 0; margin: 7px 0 7px 7px;  }
// 		.bb-albumCoverOverlay {
// 			height: 100%;
// 			width: 100%;
// 			position: absolute;
// 			-webkit-background-size: cover;
// 			-moz-background-size: cover;
// 			background-size: cover;
// 			-o-background-size: cover;
// 			background-position: center;
// 			position: absolute; top: 0; left: 0; margin: 5px; 
// 		}
// 	}
//}


// Navigation

.navbar-custom {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 1;
	background-color: transparent;
	border-bottom: none;
	padding: 0 1rem;
	@include sans-serif-font;

	.navbar-brand {
		@include quantico-font;
		font-weight: 700;
		font-size: 25px;
		text-transform: uppercase;
		
		font-size: 30px;
		color: $theme-secondary;
		margin: 0;
		padding: 0 0 0 20px;
		line-height: 60px;
		margin-left: -15px;
	}
	.navbar-tagline {
		font-weight: 400;
		font-size: 15px;
		color: #FFF;
		margin: 0;
		padding: 0 0 0 20px;
		line-height: 60px;
	}
	.navbar-header {
		.navbar-toggle {
			color: $gray-light;
			font-weight: 800;
			text-transform: uppercase;
			font-size: 12px;
		}
	}
	.nav {
		li {
			a {
				text-transform: uppercase;
				font-size: 12px;
				font-weight: 800;
				letter-spacing: 1px;
				font-size: 14px;

				color: white;
				padding: 20px;
				&:hover,
				&:focus {
					color: $theme-secondary;
					text-decoration: none;
				}
			}
		}
	}
	@media only screen and (max-width: 992px) {
		.navbar-tagline {
			display: none;	
		}
	}
}

//
// Burger menu custom styles
//
.bm-burger-button {
	position: fixed;
	width: 40px;
	height: 20px;

	left: 12px;
	top: 20px;
	position: absolute;
	z-index: 10;
	display: none;

	span {
		margin: 0 auto;
		width: 24px;
		height: 20px;
	}


	button {
		z-index: 50;
	}
	
	@media only screen and (max-width: 992px) {
		display: block;
	}
}

.right .bm-burger-button {
	left: initial;
	right: 36px;
}

.bm-burger-bars {
  	background: #373a47;
	background: $theme-secondary;
}

.bm-morph-shape {
  	fill: #373a47;
	background: #1a171a;
}

.bm-menu-wrap {
	background: #1a171a;
}

.bm-menu {
  	background: #373a47;
	background: #1a171a;    
	padding: 2.5em 1.5em 0;
    font-size: 1.15em;

	/* attempt to fix player overlay */
	padding-bottom: 66px;

	a {
		color: #b8b7ad;

		&:hover,
		&:focus {
			color: #c94e50;
			color: $theme-secondary;
			text-decoration: none;
		}
	}

	hr {
		opacity: 0.2;
	}
}

.bm-cross {
	background: #888;
}

.bm-menu-logo {
	width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
}

.bm-item-list a {
  padding: 10px;
  display: block;
  outline: none;
  font-size: 20px;

  span {
    margin-left: 10px;
    font-weight: 600;
  }
}

a.bm-subscribe {
	font-size: 60%;
}

.menu-1 {
  .bm-cross {
    background: #bdc3c7;
	color: #b8b7ad;
  }

  .bm-menu {
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
}

.menu-2 {
  .bm-cross {
    background: #999;
  }

  .bm-menu {
    padding: 3.4em 1em 0;
  }

  a {
    padding: 1em;
  }

	i {
		font-size: 1.7em;
		vertical-align: middle;
		color: #282a35;
		color: $theme-secondary;
	}
}

.menu-3 {
  .bm-cross {
    background: #888;
  }

  .bm-menu {
    padding: 2em 1em;
    font-size: 1.15em;
  }

  i {
    opacity: 0.5;
  }

  span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.75em;
  }
}

.menu-4 {
  .bm-cross {
    background: #888;
  }

  h2 {
    margin: 0 auto;
    padding: 2em 1em;
    color: rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    i {
      margin-left: 0.2em;
    }

    span {
      font-size: 1.6em;
      font-weight: 700;
    }
  }

  a {
    padding: 1em;
    text-transform: uppercase;
    transition: background 0.3s, box-shadow 0.3s;
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0.2);

    span {
      letter-spacing: 1px;
      font-weight: 400;
    }

    &:hover,
    &:focus {
      background: rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
      color: #b8b7ad;
    }
  }
}


.sidebar {	
	h3.section-title {
		font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
		font-size: 21px;
		color: #1a171a;
		font-weight: 600;
		text-transform: uppercase;
		margin: 30px 0 50px 0;
		letter-spacing: 2px;    
		line-height: 30px;
	}

	.super-friend {
		position: relative;
		background-color: $gray-light; 
		background: no-repeat center center; 
		background-attachment: scroll;
		overflow: hidden;

		a {
			display: block;

			img {
				cursor: pointer;
			}
		}

		.background {
			@include background-cover;
		}

		.background-overlay {
			position: absolute; width: 100%; height: 100%; top: 0; left: 0;
			// background-image: -webkit-gradient(linear, left top, right bottom, from(rgba(51,51,51,0.8)), to(rgba(51,51,51,0.8)));
			// background-image: -webkit-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
			// background-image: -moz-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
			// background-image: -ms-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
			// background-image: -o-linear-gradient(top left, rgba(51,51,51,0.8), rgba(51,51,51,0.8));
			background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8));
		}
	}

	a {
		border-bottom: 1px solid #f0f0f0;
		text-decoration: none;

		:hover {
			text-decoration: none;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		margin-top: 30px;

		li {
			font-size: 16px;
			text-transform: uppercase;
			padding-top: 4px;
			padding-bottom: 14px;
		}
	}
}

.progress-bar {
    overflow: inherit;
    background-color: transparent;
}
