@import './vars.scss';

@mixin clearfix() {
  &::before,
  &::after {
    clear: both;
    content: '';
    display: table;
    zoom: 1;
  }
}
@mixin hidden() {
  display: none !important;
}
@mixin text-center() {
  text-align: center;
}
@mixin transition($time:0.3s) {
  transition: all $time $common-animate-type;
}
@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin btn-hover($color:$default-color,$lighten:3%) {
  color: #fff;
  background-color: $color;
  border: 1px solid $color;
  &:hover,
  &:active {
    background-color: lighten($color, $lighten);
  }
}

@mixin ellipsis-n($n:2) {
  -webkit-line-clamp: $n;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
}
@mixin ellipsis-1() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin overwrite-progress-bar() {
  .rc-slider {
    @media screen and (max-width: 767px) {
      margin: 0 5px;
    }
    &-rail {
      background-color: $base-color;
    }
    &-handle,
    &-track {
      background-color: $primary-color;
    }
    &-handle {
      border: 2px solid #fff;

      &:active {
        box-shadow: 0 0 2px $primary-color;
      }
    }
  }
}
.react-jinke-music-player-main {
  &:focus {
    outline: none;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  * {
    box-sizing: border-box;
  }
  .text-center {
    text-align: center;
  }
  .hidden {
    @include hidden();
  }
  .loading {
    display: inline-flex;
    animation: audioLoading 1s linear infinite;
    svg {
      font-size: 24px;
      color: $primary-color;
    }
  }
  .translate {
    animation: translate $common-animate-time forwards;
  }
  .scale {
    animation: scaleTo $common-animate-time forwards;
  }

  @keyframes playing {
    to {
      transform: rotateX(360deg);
    }
  }
  @keyframes audioLoading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes scale {
    from {
      transform: scale(0);
    }
    50% {
      opacity: 0.6;
      transform: scale(1.5);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
  @keyframes scaleTo {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes scaleFrom {
    from {
      transform: scale(1);
      opacity: 1;
    }
    to {
      transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes imgRotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes fromTo {
    from {
      transform: scale(1) translate3d(0, 110%, 0);
    }
    to {
      transform: scale(1) translate3d(0, 0, 0);
    }
  }
  @keyframes fromOut {
    from {
      transform: scale(1) translate3d(0, 0, 0);
    }
    to {
      transform: scale(1) translate3d(0, 110%, 0);
    }
  }
  @keyframes fromDown {
    from {
      transform: scale(1) translate3d(0, -110%, 0);
    }
    to {
      transform: scale(1) translate3d(0, 0, 0);
    }
  }

  @keyframes translate {
    from {
      transform: translate3d(100%, 0, 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes remove {
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(-100%, 0, 0);
      opacity: 0;
    }
  }
  .img-rotate-pause {
    animation-play-state: paused !important;
  }
}
