@mixin transition-all() {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

@mixin background-cover() {
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -moz-transition: all 1.0s;
    -webkit-transition: all 1.0s;
    transition: all 1.0s;
    -moz-transform: scale(1,1);
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    background-position: center;
}

@mixin quantico-font() {
	font-family: 'Quantico', sans-serif;
}

@mixin sans-serif-font() {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;    
}
