@import './vars.scss';
@import './mixins.scss';

.audio-lists-panel-drag-line {
  border: 1px solid $primary-color !important;
}
.audio-lists-panel {
  overflow: hidden;
  position: fixed;
  right: 33px;
  bottom: $music-player-panel-height;
  z-index: $player-full-z-index;
  width: $lists-panel-width;
  height: $lists-panel-height;
  color: rgba(255, 255, 255, 0.8);
  transform-origin: right bottom;
  background-color: $panel-bg;
  transform: scale(0);
  display: none\9;
  transition: background-color $common-animate-time;
  border-radius: $border-radius $border-radius 0 0;
  svg {
    font-size: 24px;
  }
  &.show {
    animation: scaleTo $common-animate-time forwards;
    display: block\9;
  }
  &.hide {
    animation: scaleFrom $common-animate-time forwards;
    display: none\9;
  }
  @media screen and (max-width: 767px) {
    width: 100% !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto !important;
    transform-origin: bottom center;
    background-color: $panel-bg-mobile;
    border-radius: 0;
    &.show {
      animation: fromTo $common-animate-time forwards;
      display: block\9;
    }
    &.hide {
      animation: fromOut $common-animate-time forwards;
      display: none\9;
    }
  }
  &-header {
    border-bottom: 1px solid lighten($panel-bg, 1%);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: background-color, border-bottom $common-animate-time;
    &-close-btn,
    &-delete-btn {
      float: right;
      cursor: pointer;
    }
    &-delete-btn {
      svg {
        font-size: 21px;
        @media screen and (max-width: 767px) {
          font-size: 19px;
        }
      }
    }
    &-close-btn {
      &:hover {
        svg {
          animation: imgRotate $common-animate-time;
        }
      }
    }
    &-line {
      width: 1px;
      background: #fff;
      float: right;
      height: 20px;
      margin: 18px 10px 0 10px;
    }
    &-title {
      height: $panel-header-height;
      line-height: $panel-header-height;
      padding: 0 20px;
      font-size: 16px;
      margin: 0;
      text-align: left;
      font-weight: 500;
      transition: color $common-animate-time;
    }
  }
  &-content {
    overflow-x: hidden;
    overflow-y: auto;
    height: $lists-panel-height - $panel-header-height - 1px;
    @media screen and (max-width: 767px) {
      width: 100% !important;
      height: calc(100vh - #{$panel-header-height}) !important;
      transform-origin: bottom center;
    }
    &.no-content {
      @mixin center() {}
    }
    .no-data {
      margin-left: 10px,
    }
    .audio-item {

      border-bottom: 1px solid transparent;
      margin: 3px 20px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      transition: background-color $common-animate-time;

      &:nth-child(2n + 1) {
        background-color: $lists-panel-item-bg;
      }

      &.playing {
        background-color: $lists-panel-item-bg-playing;
        &,
        svg {
          color: $primary-color;
        }
      }

      &.remove {
        animation: remove $common-animate-time forwards;
      }

      @media screen and (max-width: 767px) {
        background-color: $lists-panel-item-bg-mobile !important;
        &.playing {
          background-color: $lists-panel-item-bg-playing-mobile !important;
        }
      }
      .player-icons {
        display: inline-flex;
        width: 30px;
        .loading {
          animation: audioLoading 0.9s linear infinite;
        }
      }
      .player-delete {
        &:hover {
          svg {
            animation: imgRotate $common-animate-time;
          }
        }
      }
      &:hover,
      &:active {
        background-color: $lists-panel-item-bg-active;
        .group:not([class='.player-delete']) {
          svg {
            color: $primary-color;
          }
        }
      }
      .group {
        display: inline-flex;
      }
      .player-name {
        flex: 1;
        padding: 0 20px 0 10px;
        display: inline-block;
        @include ellipsis-1();
        transition: color $common-animate-time;
      }
      .player-singer {
        font-size: 12px;
        transition: color $common-animate-time;
        display: inline-block;
        width: 85px;
        @include ellipsis-1();
      }
      .player-delete {
        width: 30px;
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
}
