
$theme-primary:    rgba(51,51,51,0.6);
$theme-secondary: #3399CC;

$gray-base:        #000;
$gray-darker:      lighten($gray-base, 13.5%); // #222
$gray-dark:        lighten($gray-base, 20%);   // #333
$gray:             lighten($gray-base, 33.5%); // #555
$gray-light:       lighten($gray-base, 46.7%); // #777
$gray-lighter:     lighten($gray-base, 93.5%); // #eee
