$primary-color: #3399CC; //#31c27c;
$primary-color-light: #000; //#fff;
$primary-color-dark: rgba(0, 0, 0, 0.75);

$default-color: #d9d9d9;
$bg-color: #f7f8fa;
$border-color: #d9d9d9;
$panel-bg-light: $primary-color-light;
$controller-bg-light: $primary-color-light;
$panel-bg-dark: $primary-color-dark;
$controller-bg-dark: darken($primary-color-light, 10%);

$border-radius: 4px;
$music-player-panel-height: 80px;

$lists-panel-width: 480px;
$lists-panel-height: 410px;
$lists-panel-item-bg: rgba(0, 0, 0, 0.1);
$lists-panel-item-bg-playing: rgba(0, 0, 0, 0.35);
$lists-panel-item-bg-active: rgba(0, 0, 0, 0.35);
$lists-panel-item-bg-mobile: rgba(0, 0, 0, 0.15);
$lists-panel-item-bg-playing-mobile: rgba(0, 0, 0, 0.75);
$lists-panel-item-bg-active-mobile: rgba(0, 0, 0, 0.15);
$lists-panel-item-bg-light: #fff;
$lists-panel-item-bg-playing-light: darken($lists-panel-item-bg-light, 5%);
$lists-panel-item-bg-active-light: darken($lists-panel-item-bg-light, 2%);
$lists-panel-item-signer-color-light: rgba(162, 162, 162, 0.45);
$panel-header-height: 50px;
$panel-bg: rgba(0, 0, 0, 0.75);
$panel-bg-mobile: rgba(0, 0, 0, 0.75);

$font-color: #444;
$player-width: 80px;
$player-height: $player-width;
$player-width-mobile: 60px;
$player-height-mobile: $player-width-mobile;
$base-color: rgba(255, 255, 255, 0.6);
$progress-load-bar-bg-color: rgba(0, 0, 0, 0.06);
$common-animate-type: cubic-bezier(0.43, -0.1, 0.16, 1.1);
$common-animate-time: 350ms $common-animate-type;
$progress-bar-bg-color-light: rgba(0, 0, 0, 0.09);
$progress-bar-bg-color-dark: #fff;
$player-lyric-color: $primary-color;
$player-lyric-font-size: 36px;
$player-lyric-font-size-mobile: 16px;

$player-lyric-z-index: 998;
$player-lyric-z-index-mobile: 999;
$player-mini-z-index: 999;
$player-full-z-index: 999;
$mode-tip-z-index: 1000;

$scroll-bar-width: 8px;

//$mobile: 'screen and (max-width: 767px)';
