@import './vars.scss';
@import './mixins.scss';
.react-jinke-music-player-mobile {
  $mobile-padding: 20px;
  width: 100%;
  position: fixed;
  z-index: $player-full-z-index;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  padding: $mobile-padding;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $panel-bg-mobile;
  overflow: hidden;
  > .group {
    flex: 1 1 auto;
  }
  .show {
    animation: mobile-bg-show $common-animate-time forwards;
  }
  .hide {
    animation: mobile-bg-hide $common-animate-time forwards;
  }
  &-play-model-tip {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 35px;
    line-height: 35px;
    background-color: $primary-color;
    color: #fff;
    padding: 0 $mobile-padding;
    z-index: $mode-tip-z-index;
    box-shadow: 0 2px $mobile-padding rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -105%, 0);
    transition: transform $common-animate-time;

    &-title {
      margin-right: 12px;
      svg {
        animation: none !important;
        vertical-align: text-bottom !important;
        @media screen and (max-width: 767px) {
          font-size: 19px;
          color: #fff !important;
        }
      }
    }
    &-text {
      font-size: 14px;
    }
    &.show {
      transform: translate3d(0, 0, 0);
    }
  }
  &-header {
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    animation: fromDown $common-animate-time forwards;
    &-title {
      flex-basis: 80%;
      text-align: center;
      font-size: 20px;
      transition: color $common-animate-time;
      @include ellipsis-1();
    }
    .item {
      display: inline-flex;
      width: 50px;
    }
    &-left,
    &-right {
      display: inline-flex;
      width: 50px;
    }
    &-right {
      justify-content: flex-end;
      color: $base-color;
      cursor: pointer;
      svg {
        font-size: 25px;
      }
    }
  }
  &-singer {
    animation: fromDown $common-animate-time forwards;
    padding: 12px 0;
    @media screen and (max-width: 320px) {
      padding: 0;
    }
    &-name {
      position: relative;
      font-size: 14px;
      transition: color $common-animate-time;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 1px;
        top: 9px;
        border-radius: 2px;
        background-color: $base-color;
        transition: background-color $common-animate-time;
      }
      &:after {
        left: -25px;
      }
      &:before {
        right: -25px;
      }
    }
  }
  &-cover {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 1px 3px rgba(0, 0, 0, 0.1);
    margin: 15px auto;
    animation: fromTo $common-animate-time forwards;
    overflow: hidden;
    transition: box-shadow, border $common-animate-time;
    @media screen and (max-width: 320px) {
      width: 230px;
      height: 230px;
      margin: 10px auto;
    }
    .cover {
      width: 100%;
      animation: imgRotate 30s linear infinite;
    }
  }
  &-progress {
    justify-content: space-around;
    display: flex;
    align-items: center;
    .current-time,
    .duration {
      display: inline-flex;
      width: 55px;
      font-size: 12px;
      color: $base-color;
      transition: color $common-animate-time;
    }
    .current-time {
      margin-right: 5px;
    }
    .duration {
      margin-left: 5px;
    }
    .duration {
      justify-content: flex-end;
    }
    .progress-bar {
      flex: 1 1 auto;
    }
    @mixin overwrite-progress-bar() {}
  }
  &-switch {
    animation: fromDown $common-animate-time forwards,
  }
  &-toggle {
    padding: 17px 0;
    .group {
      cursor: pointer,
      svg {
        font-size: 40px,
      }
    }
    .play-btn {
      padding: 0 40px,
      svg {
        font-size: 60px,
      }
    }
    @media screen and (max-width: 320px) {
      padding: 10px 0;
      > .group {
        svg {
          font-size: 32px;
        }
      }
      .play-btn {
        svg {
          font-size: 50px;
        }
      }
    }
  }
  &-toggle,
  &-progress {
    animation: fromTo $common-animate-time forwards;
  }
  &-operation {
    animation: fromTo $common-animate-time forwards;
    .items {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .item {
        cursor: pointer;
        flex: 1;
        text-align: center;
        svg {
          color: $base-color;
          font-size: 25px;
          @media screen and (max-width: 320px) {
            font-size: 20px;
          }
        }
      }
    }
  }
  @keyframes mobile-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes mobile-bg-hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
